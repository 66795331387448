// i18next-extract-mark-ns-start google-pay

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Card, CardImage, CardWrapper} from 'components/Card';
import gpay_monei from 'images/gpay_monei.svg';
import React from 'react';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import CardsContainer from 'components/CardsContainer';
import cart from 'images/cart.svg';
import click from 'images/click1.svg';
import mobile_payment from 'images/mobile_payment.svg';
import coinstack from 'images/coinstack.svg';
import site_template from 'images/site_template.svg';
import person from 'images/person.svg';
import Bold from 'components/Bold';
import {Partners} from 'components/Partners';
import {InternalPageLink} from 'components/links/Pages';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AnchorLink} from 'components/AnchorLink';
import {PrismicPage} from 'components/links/PrismicPage';
import moneiPayFormEs from 'images/monei_pay_form_es.png';
import moneiPayFormEn from 'images/monei_pay_form_en.png';
import styled from 'styled-components';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {FaqsSection} from 'components/FaqsSection';

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const GooglePay: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const moneiPayForm = ['es', 'ca'].includes(language) ? moneiPayFormEs : moneiPayFormEn;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const faqs = [
    {
      header: t('How do I activate Google Pay?'),
      text: t(
        'As soon as your account has been approved, Google Pay will be enabled by default. You can go to MONEI Dashboard to make adjustments to your configured payment methods.'
      ),
      content: (
        <Trans>
          As soon as your account has been approved, Google Pay will be enabled by default. You can
          go to{' '}
          <AnchorLink href="https://dashboard.monei.com/?action=signIn">MONEI Dashboard</AnchorLink>{' '}
          to make adjustments to your configured payment methods.
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for Google Pay?'),
      text: t(
        'There are no extra costs to accept Google Pay through MONEI. You only have to pay the regular dynamic credit card fees.'
      ),
      content: (
        <Trans>
          There are no extra costs to accept Google Pay through MONEI. You only have to pay the
          regular{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/360017954318-Tarifas-de-MONEI">
            dynamic credit card fees.
          </AnchorLink>{' '}
        </Trans>
      )
    },
    {
      header: t('How are Google Pay transactions paid out to me?'),
      text: t(
        'Technically speaking, Google Pay payments are credit card payments. Google Pay is the digital wallet users use to store their credit card information. For this reason, the pay-out process is the same as credit card settlements and there are no additional transaction fees. Learn more here.'
      ),
      content: (
        <Trans>
          Technically speaking, Google Pay payments are credit card payments. Google Pay is the
          digital wallet users use to store their credit card information. For this reason, the
          pay-out process is the same as credit card settlements and there are no additional
          transaction fees.{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/360017952658-Settlements-When-will-I-get-paid">
            Learn more here.
          </AnchorLink>
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        title={t('Accept Google Pay Online & In-Person')}
        description={t(
          'Increase sales and customer satisfaction by accepting Google Pay online, in-store, at your restaurant, or anywhere your business takes you. Start here ››'
        )}
      />
      <IndexBackground
        sx={{
          left: {all: '108%', xl: '119%', lg: '1325px'},
          top: {all: '-100%', xl: '-108%', lg: '-125%'},
          height: {all: '2150px', lg: '1960px'},
          width: {all: '1530px'},
          transform: {
            all: 'rotate(-58deg) skew(0deg, 17deg)',
            xl: 'rotate(-58deg) skew(2deg,33deg)',
            lg: 'rotate(-58deg) skew(12deg,12deg)'
          }
        }}>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Accept Google Pay</Trans>
              </SectionHeader>
              <Trans parent="p">
                Hundreds of millions of people use Google Pay to store credit and debit card
                information for purchases. Let your customers seamlessly pay online and in person,
                without having to add their payment details each time.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={gpay_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>

      <Content style={{marginTop: '40px'}}>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>
              Benefits of accepting Google Pay in your online store, brick-and-mortar location,
              restaurant, or on the go with MONEI
            </Trans>
          </SectionHeader>
          <CardsContainer style={{marginTop: '60px'}}>
            <Card>
              <CardImage src={site_template} alt="" style={{top: -55, width: '100px'}} />
              <CardWrapper>
                <Trans>
                  <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                    Integrate
                  </AnchorLink>{' '}
                  with your e-commerce website at no additional cost
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{top: -47, width: '100px'}} />
              <CardWrapper>
                <Trans parent="p">Increase sales and customer engagement</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={cart} alt="" style={{width: '90px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Reduce abandoned carts with a streamlined payment experience and fewer clicks
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click} alt="" style={{width: '100px', top: -40}} />
              <CardWrapper>
                <Trans>Build trust by customizing the appearance of your payment page</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={mobile_payment} alt="" style={{width: '130px', top: -50}} />
              <CardWrapper>
                <Trans parent="p">
                  Save money on bulky POS hardware —{' '}
                  <InternalPageLink slug="monei-pay">
                    take payments from your phone
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={person} alt="" style={{width: '100px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Minimize queues with a faster, contactless in-person payment process
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions>
            <SignUpButton variant="dark" style={{marginRight: 15}}>
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section centered>
            <SectionImage
              src={moneiPayForm}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              height={650}
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>How does Google Pay work for customers?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Google Pay lets users store their credit and debit card information in the app so
                they can make purchases with the press of a button, and without ever having to share
                their personal details with merchants.
              </Trans>
              <Trans parent="p">
                Completing online purchases in fewer clicks and a more convenient in-person payment
                experience leads to more sales and increased customer satisfaction. Once users add
                payment details to their Google Pay app, it’s available for all future purchases.
              </Trans>

              <Trans parent="p">
                <Bold>How Google Pay online payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">
                  MONEI automatically detects if the customer is an Android user and shows the
                  Google Pay button on the payment page
                </Trans>
                <Trans parent="li">Customer clicks the Google Pay button at checkout</Trans>
                <Trans parent="li">Customer selects a saved payment method or adds a new one</Trans>
                <Trans parent="li">Customer clicks submit order</Trans>
              </List>

              <Trans parent="p">
                <Bold>How Google Pay physical payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">Customer opens the Google Pay app on their smartphone</Trans>
                <Trans parent="li">
                  Customer scans a{' '}
                  <InternalPageLink slug="monei-pay/qr">digital QR code</InternalPageLink> or waves
                  their smartphone over an NFC-enabled device
                </Trans>
                <Trans parent="li">Customer authorizes the payment</Trans>
              </List>

              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section centered column style={{maxWidth: '940px', margin: 'auto', textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Offer Google Pay at your business with MONEI</Trans>
          </SectionHeader>
          <Trans parent="p">
            Whether you have an{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              online shop
            </PrismicPage>
            , multiple{' '}
            <InternalPageLink slug="monei-pay/retail-POS-system">retail locations</InternalPageLink>
            , a{' '}
            <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
              restaurant
            </InternalPageLink>
            , or need to collect payment for{' '}
            <InternalPageLink slug="monei-pay/pos-for-freelancers">your services</InternalPageLink>,{' '}
            MONEI is here to help.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept Google Pay online</Bold>
          </Trans>
          <Trans parent="p">
            <AnchorLink href="https://docs.monei.com/docs/payment-methods/google-pay/">
              Add Google Pay
            </AnchorLink>{' '}
            to all major e-commerce platforms with a simple plugin, or integrate with your
            custom-built website using our{' '}
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept physical payments with Google Pay</Bold>
          </Trans>
          <Trans parent="p">
            Download the <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
            <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
            <IosDownloadLink>iOS</IosDownloadLink>) to take Google Pay payments from your phone and
            view online and physical transaction history from anywhere.
          </Trans>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>
      <ReviewsBackground style={{marginBottom: '100px'}}>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Partners />
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Google Pay FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default GooglePay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "google-pay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
